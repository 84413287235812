import { create360ImageMesh, createImageMesh } from "../utils/create-image-mesh";
import { addComponent } from "bitecs";
import { addObject3DComponent } from "../utils/jsx-entity";
import { ProjectionMode } from "../utils/projection-mode";
import { MediaVideo, MediaVideoData } from "../bit-components";

export function inflateVideo(world, eid, { texture, ratio, projection, autoPlay, video }) {
  const mesh =
    projection === ProjectionMode.SPHERE_EQUIRECTANGULAR
      ? create360ImageMesh(texture, ratio)
      : createImageMesh(texture, ratio);
  addObject3DComponent(world, eid, mesh);
  addComponent(world, MediaVideo, eid);
  MediaVideo.autoPlay[eid] = autoPlay ? 1 : 0;
  MediaVideo.ratio[eid] = ratio;
  MediaVideoData.set(eid, video);
  return eid;
}
