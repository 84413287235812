import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Modal } from "../modal/Modal";
import { Button } from "../input/Button";
import { CheckboxInput } from "../input/CheckboxInput";
import { ReactComponent as EnterIcon } from "../icons/Enter.svg";
import { ReactComponent as VRIcon } from "../icons/VR.svg";
import { ReactComponent as ShowIcon } from "../icons/Show.svg";
import { ReactComponent as SettingsIcon } from "../icons/Settings.svg";
import styles from "./RoomEntryModal.scss";
import styleUtils from "../styles/style-utils.scss";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { Column } from "../layout/Column";
import { Row } from "../layout/Row";
import { AppLogo } from "../misc/AppLogo";
import { FormattedMessage } from "react-intl";
import { LegalMessage } from "../auth/LegalMessage";
import { TERMS, PRIVACY } from "../../constants";
import configs from "../../utils/configs";

export function RoomEntryModal({
  className,
  roomName,
  showJoinRoom,
  onJoinRoom,
  showEnterOnDevice,
  onEnterOnDevice,
  showSpectate,
  onSpectate,
  showRoomSettings,
  onRoomSettings,
  ...rest
}) {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const onAcceptTerms = useCallback(() => {
    setAcceptedTerms(state => !state);
  }, []);
  const AcceptTermsLabel = () => {
    return (
      <FormattedMessage
        id="room-entry-modal.agb-accept"
        defaultMessage="I confirm that I understand the legal note and risks outlined, and consent to the processing of my Personal Data as outlined above. I acknowledge and accept the website terms of use and data privacy policy."
      />
    );
  };

  const termsUrl = configs.link("terms_of_use", TERMS);
  const privacyUrl = configs.link("privacy_notice", PRIVACY);

  const breakpoint = useCssBreakpoints();
  return (
    <Modal className={classNames(styles.roomEntryModal, className)} disableFullscreen {...rest}>
      <Column center className={styles.content}>
        {breakpoint !== "sm" && breakpoint !== "md" && <AppLogo className={styles.logo} />}
        <div className={styles.termsAndConditions}>
          <LegalMessage termsUrl={termsUrl} privacyUrl={privacyUrl} />
          <CheckboxInput
            tabIndex="0"
            type="checkbox"
            checked={acceptedTerms}
            label={<AcceptTermsLabel />}
            onChange={onAcceptTerms}
            className={styles.termsAndConditionsCheckbox}
          />
        </div>
        <Row className={styles.buttons} flexClassName={styles.buttonsRow}>
          {showJoinRoom && (
            <Button preset="accent4" onClick={onJoinRoom} disabled={!acceptedTerms}>
              <EnterIcon />
              <span>
                <FormattedMessage id="room-entry-modal.join-room-button" defaultMessage="Join Room" />
              </span>
            </Button>
          )}
          {showEnterOnDevice && (
            <Button preset="accent5" onClick={onEnterOnDevice} disabled={!acceptedTerms}>
              <VRIcon />
              <span>
                <FormattedMessage id="room-entry-modal.enter-on-device-button" defaultMessage="Enter On Device" />
              </span>
            </Button>
          )}
          {showSpectate && (
            <Button preset="accent2" onClick={onSpectate} disabled={!acceptedTerms}>
              <ShowIcon />
              <span>
                <FormattedMessage id="room-entry-modal.spectate-button" defaultMessage="Spectate" />
              </span>
            </Button>
          )}
          {showRoomSettings && breakpoint !== "sm" && (
            <>
              <hr className={styleUtils.showLg} />
              <Button preset="transparent" className={styleUtils.showLg} onClick={onRoomSettings}>
                <SettingsIcon />
                <span>
                  <FormattedMessage id="room-entry-modal.room-settings-button" defaultMessage="Room Settings" />
                </span>
              </Button>
            </>
          )}
        </Row>
      </Column>
    </Modal>
  );
}

RoomEntryModal.propTypes = {
  className: PropTypes.string,
  roomName: PropTypes.string.isRequired,
  showJoinRoom: PropTypes.bool,
  onJoinRoom: PropTypes.func,
  showEnterOnDevice: PropTypes.bool,
  onEnterOnDevice: PropTypes.func,
  showSpectate: PropTypes.bool,
  onSpectate: PropTypes.func,
  showRoomSettings: PropTypes.bool,
  onRoomSettings: PropTypes.func
};

RoomEntryModal.defaultProps = {
  showJoinRoom: true,
  showEnterOnDevice: true,
  showSpectate: true,
  showRoomSettings: true
};
